import { Interface, Result } from '@ethersproject/abi';
import { Contract, ContractInterface } from '@ethersproject/contracts';
import { JsonRpcProvider } from '@ethersproject/providers';
import axios from 'axios';

import { getRPCUrl } from '@src/config';
import { ContractType } from '@src/ts/constants';
import { ContractConfig } from '@src/ts/interfaces';
import { Evt } from '@src/utils/event';

// ! RPCs that have given a good response when checking the network but always a bad resposne otherwise
const RPC_BLACKLIST = [
    'https://core.drpc.org',
    'https://eth.drpc.org',
    'https://polygon.drpc.org',
    'https://api.mycryptoapi.com/eth',
    'https://rpc-mainnet.matic.network',
    'https://matic-mainnet-full-rpc.bwarelabs.com',
    'https://matic-mainnet.chainstacklabs.com',
    'https://rpc.blocknative.com/boost',
    'https://api.mycryptoapi.com/eth',
    'https://rpc-mainnet.maticvigil.com',
    'https://matic-mainnet-full-rpc.bwarelabs.com',
    'https://arbitrum-mainnet.infura.io/v3/${INFURA_API_KEY}',
    'https://linea-mainnet.infura.io/v3/${INFURA_API_KEY}',
    'https://arb-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}',
    'https://mainnet.infura.io/v3/${INFURA_API_KEY}',
    'https://api.zan.top/node/v1/arb/one/public',
    'https://bsc-dataseed4.defibit.io',
    'https://bsc-dataseed1.defibit.io/',
    'https://rpc.flashbots.net/',
    'https://bsc-dataseed1.bnbchain.org',
    'https://bsc-dataseed.bnbchain.org',
];

export interface IRpcManager {
    /**
     * Event that is triggered when a new provider is set
     */
    NewProvider: Evt<JsonRpcProvider>;
    /**
     * The current provider
     */
    provider: JsonRpcProvider;
    /**
     * Function that updates the provider with the best rpc url
     * This is exposed so that the provider can be updated manually
     * This is called every update_interval milliseconds regardless
     */
    updateProvider(): Promise<void>;
}

export interface IContractManager {
    /**
     * Function that gets a contract based on the type and chain
     * @param type ContractType enum
     * @param chainId chain id for the contract
     */
    getContract(type: ContractType, chainId?: number): ContractWrapper;
    /**
     * Function that gets a contracts address based on the type and chain
     * @param type ContractType enum
     * @param chainId chain id for the contract
     */
    getContractAddress(type: ContractType, chainId?: number): string;
    /**
     * Function that gets a contract based on the address and type
     * @param address contract address
     * @param type contract type
     * @param chainId chain id for the contract
     */
    getContractByAddress(
        address: string,
        type: ContractType,
        chainId?: number,
    ): ContractWrapper;
    /**
     * Function that gets a contract based on the address and abi
     * @param address contract address
     * @param abi contract abi
     * @param chainId chain id for the contract
     */
    getContractByAddressAndABI(
        address: string,
        abi: ContractInterface,
        chainId?: number,
    ): ContractWrapper;
    /**
     * Function that gets the contract interface based on the type
     * @param type ContractType enum
     */
    getContractInterface(type: ContractType): Interface;
    /**
     * Function that gets the provider based on the chain id
     * @param chainId chain id for the provider
     */
    getProvider(chainId: number): JsonRpcProvider;
    /**
     * Function that initializes the contract manager getting the best provider for each chain
     */
    init(): Promise<void>;
    /**
     * Function that makes a multicall to the multicall contract
     * @param calls Array of calls to make
     * @param params Parameters for the multicall
     */
    multicall(calls: Call[], params?: MultiCallParams): Promise<Result[]>;
}

interface RpcEndpoint {
    url: string;
    latency: number;
}

// RPC Manager class that manages the RPC provider, has event that can be listened to for new provider updates
// a function is run every <update_interval> milliseconds to check the latency of the RPC urls and update the provider with the best one
export class RpcManager implements IRpcManager {
    public NewProvider = new Evt<JsonRpcProvider>();

    public provider: JsonRpcProvider;

    // default update every 30 seconds
    constructor(private chain_id: number, update_interval = 30_000) {
        // initialize the provider with one of the rpcs store in the config - this is for client side where we can't make http request before the page loads. Server side props reqiests should call updateProvider before making any requests
        this.provider = new JsonRpcProvider(getRPCUrl(chain_id));
        // bind the function to the class for setInterval
        this.updateProvider = this.updateProvider.bind(this);
        // get the rpc urls and set the interval to update the provider
        setInterval(this.updateProvider, update_interval);
    }

    public async updateProvider(): Promise<void> {
        const network = await this.getNetwork();
        const rpcs = (network.rpc || []).filter(
            (rpc) => rpc.startsWith('http') && !RPC_BLACKLIST.includes(rpc),
        );

        const responses = await this.checkUrls(rpcs);
        responses.sort((a, b) => a.latency - b.latency);

        const best = responses[0];

        if (!best) throw new Error('No RPC urls found');

        if (best.url === this.provider.connection.url) return;

        this.provider = new JsonRpcProvider(best.url);
        this.NewProvider.trigger(this.provider);
    }

    private async getNetwork() {
        const { data } = await axios.get('https://chainid.network/chains.json');
        const network = data.find((n) => n.chainId === this.chain_id);

        if (!network)
            throw new Error(`Network not found for chain id ${this.chain_id}`);

        return network;
    }

    private async checkUrls(urls: string[]): Promise<RpcEndpoint[]> {
        const promises = urls.map(async (url) => {
            try {
                const start = +new Date();
                await axios.post(
                    url,
                    {
                        jsonrpc: '2.0',
                        id: 1,
                        method: 'eth_blockNumber',
                        params: [],
                    },
                    { timeout: 3000 },
                );

                const latency = Date.now() - start;
                return { url, latency };
            } catch (error) {
                return { url, latency: Number.MAX_SAFE_INTEGER };
            }
        });
        return Promise.all(promises);
    }
}

// ContractWrapper class that wraps the ethers contract class and allows for updating the provider
// Always exposes the latest provider
export class ContractWrapper {
    public contract: Contract;

    constructor(
        private address: string,
        private abi: ContractInterface,
        private provider: JsonRpcProvider,
    ) {
        this.contract = new Contract(address, abi, provider);
    }

    public updateProvider(provider: JsonRpcProvider): void {
        this.provider = provider;
        this.contract = new Contract(this.address, this.abi, provider);
    }
}

// Manager for contracts on a single chain
// caches the contracts and updates the provider on provider change
export class SinlgeChainContractManager {
    private contracts: { [key: string]: ContractWrapper } = {};

    constructor(private rpcManager: IRpcManager) {
        this.updateProvider = this.updateProvider.bind(this);

        rpcManager.NewProvider.on(this.updateProvider);
    }

    public init(): Promise<void> {
        return this.rpcManager.updateProvider();
    }

    public getContract(
        address: string,
        abi: ContractInterface,
    ): ContractWrapper {
        if (this.contracts[address]) return this.contracts[address];

        this.contracts[address] = new ContractWrapper(
            address,
            abi,
            this.rpcManager.provider,
        );

        return this.contracts[address];
    }

    private updateProvider(provider: JsonRpcProvider): void {
        for (const contract of Object.values(this.contracts)) {
            contract.updateProvider(provider);
        }
    }
}

export class ContractManager implements IContractManager {
    private managers: { [key: number]: SinlgeChainContractManager } = {};

    constructor(
        private rpcManagers: { [key: number]: RpcManager },
        private config: ContractConfig,
        private abis: { [key in ContractType]: ContractInterface },
        private default_chain_id: number,
    ) {
        for (const chain_id of Object.keys(rpcManagers)) {
            this.managers[chain_id] = new SinlgeChainContractManager(
                rpcManagers[chain_id],
            );
        }
    }

    public async init(): Promise<void> {
        await Promise.all(
            Object.values(this.managers).map((manager) => manager.init()),
        );
    }

    public getContract(type: ContractType, chainId?: number): ContractWrapper {
        if (!chainId) chainId = this.default_chain_id;

        return this.managers[chainId].getContract(
            this.getContractAddress(type, chainId),
            this.abis[type],
        );
    }

    public getContractByAddress(
        address: string,
        type: ContractType,
        chainId?: number,
    ): ContractWrapper {
        if (!chainId) chainId = this.default_chain_id;

        return this.managers[chainId].getContract(address, this.abis[type]);
    }

    public getContractByAddressAndABI(
        address: string,
        abi: ContractInterface,
        chainId?: number,
    ): ContractWrapper {
        if (!chainId) chainId = this.default_chain_id;

        return this.managers[chainId].getContract(address, abi);
    }

    public getContractAddress(type: ContractType, chainId?: number): string {
        if (!chainId) chainId = this.default_chain_id;

        if (
            [
                ContractType.MultiCall,
                ContractType.Investments,
                ContractType.EventFactory,
                ContractType.LegacyEventFactory,
                ContractType.CompoundStaking,
                ContractType.Vault,
                ContractType.Tiers,
                ContractType.LiquidityStaking,
            ].includes(type)
        ) {
            return this.config[type][chainId];
        }

        if (
            [ContractType.BaseToken, ContractType.PaymentToken].includes(type)
        ) {
            return this.config[type][chainId].address;
        }

        return this.config[type];
    }

    public getContractInterface(type: ContractType): Interface {
        return new Interface(JSON.stringify(this.abis[type]));
    }

    public getProvider(chainId: number): JsonRpcProvider {
        return this.rpcManagers[chainId].provider;
    }

    public async multicall(
        calls: Call[],
        params: MultiCallParams = {},
    ): Promise<Result[]> {
        let { chain_id } = params;
        if (!chain_id) chain_id = this.default_chain_id;

        const { default_iface } = params;

        const multicall = this.getContract(ContractType.MultiCall, chain_id);

        const mapped_calls = calls.map(
            ({ iface = default_iface, target, func_name, params = [] }) => ({
                target,
                callData: iface.encodeFunctionData(func_name, params),
            }),
        );
        const res = await multicall.contract.callStatic.aggregate(mapped_calls);
        return calls.map(({ iface = default_iface, func_name }, idx) =>
            iface.decodeFunctionResult(func_name, res.returnData[idx]),
        );
    }
}

export interface Call {
    target: string;
    func_name: string;
    iface?: Interface;
    params?: unknown[];
}

export interface MultiCallParams {
    default_iface?: Interface;
    chain_id?: number;
}
