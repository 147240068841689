import React from 'react';
import { Progress, Typography } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';

import NetworkIcon from '@src/components/NetworkIcon';
import { CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { ProjectEvent } from '@src/ts/interfaces';
import { parseBalance } from '@src/utils/web3';

import { useProjectContext } from '../../context';

export const CrowdfundingProgress: React.FC<{
    event: ProjectEvent;
}> = ({ event }) => {
    const {
        crowdfunding: { total_raised, hardcap } = {
            total_raised: '0',
            hardcap: '0',
        },
    } = useProjectContext();

    const announced = !BigNumber.from(hardcap).eq(0);
    const event_chain_id = event.chainId || DEFAULT_CHAIN_ID;

    const { symbol, decimals } = CONTRACT.PaymentToken[event_chain_id];
    const percent_filled =
        (Number(formatUnits(total_raised, decimals)) /
            Number(formatUnits(hardcap, decimals))) *
        100;

    if (!announced) return null;
    return (
        <div className="w-full px-6 pt-4">
            <div className="flex justify-between items-center">
                <Typography allBold size="3xl">
                    {parseBalance(total_raised, decimals, 2, true)} {symbol}
                </Typography>
                <NetworkIcon chain_id={event_chain_id} />
            </div>

            <Typography variant="secondary" className="mb-2" size="sm">
                {percent_filled.toFixed(1)}% of total goal of{' '}
                {parseBalance(hardcap, decimals, 2, true)} {symbol}
            </Typography>

            <Progress percent={percent_filled} height={'h-4'} />
        </div>
    );
};
