import React, { useContext } from 'react';
import { Media, Steps, Typography } from '@decub8/ui';
import dayjs from 'dayjs';

import { CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { DEFAULT_IDO_ROUND_DURATIONS } from '@src/constants';
import { useAppSelector } from '@src/hooks/index';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { EventType } from '@src/ts/constants';
import { ProjectEvent } from '@src/ts/interfaces';

import { useProjectContext } from '../context';

import { useQualifySteps } from './Qualify';
import { useTimedText } from './TimeText';
import { isHardcapMet, nowLiveIndex, shouldDisplayTime } from './utils';

interface StageOption {
    name: string;
    component: React.FC;
    date?: string;
    props?: { [key: string]: unknown };
}

const Stage: React.FC<{
    idx: number;
    option: StageOption;
    options: StageOption[];
    current: number;
    total: number;
    event: ProjectEvent;
    has_reached_cutoff: boolean;
    is_crowdfunding_event_over: boolean;
    is_token_claim_event_over: boolean;
    hardcap: string;
    total_raised: string;
}> = ({
    idx,
    option,
    options,
    current,
    total,
    event,
    has_reached_cutoff,
    is_crowdfunding_event_over,
    is_token_claim_event_over,
    hardcap,
    total_raised,
}) => {
    const { _userTier, _setTierDrawerOpen } = useContext(GlobalContext);
    const { name, component: Component, date, props = {} } = option;
    const { user } = useAppSelector((state) => state.auth);
    const { durations = DEFAULT_IDO_ROUND_DURATIONS } =
        event?.event_details || {};

    const { registered } = useProjectContext();

    const payment_token =
        CONTRACT.PaymentToken[event.chainId || DEFAULT_CHAIN_ID];

    const steps_to_qualify = useQualifySteps(
        user,
        _userTier,
        _setTierDrawerOpen,
        props.min_tier as number,
    );
    const start = dayjs(Number(date) * 1000);
    const time = useTimedText(start, false);
    const now_live_idx = nowLiveIndex(event);

    const should_display_time = shouldDisplayTime(
        current,
        idx,
        total,
        event,
        has_reached_cutoff,
    );

    const is_crowdfunding = event.type === EventType.Crowdfunding;

    const right_value =
        idx === now_live_idx
            ? 'Now live'
            : date && should_display_time
            ? date === 'TBA'
                ? date
                : time
            : '';

    const is_qualified = steps_to_qualify.length === 0;

    const active = current === idx;

    const whitelist_stage = idx === 0;

    const crowdfunding_stage_complete = dayjs().isAfter(
        idx === 0
            ? has_reached_cutoff
                ? dayjs(0) // if past cutoff use date in the past for the comparison of first idx
                : dayjs().add(1, 'hour') // otherwise use a date in the future
            : start.add(durations[idx - 1] || 0, 'seconds'),
    );

    const has_missed_event = !registered && has_reached_cutoff;

    const show_checkmark =
        is_qualified &&
        whitelist_stage &&
        (is_crowdfunding
            ? registered && !is_crowdfunding_event_over
            : !is_token_claim_event_over);

    const is_last_stage = idx === options.length - 1;

    const hardcap_met = isHardcapMet(
        payment_token.decimals,
        hardcap,
        total_raised,
    );

    const show_stage_closed =
        !is_last_stage &&
        (is_crowdfunding
            ? crowdfunding_stage_complete || hardcap_met
            : is_token_claim_event_over);

    return (
        <div>
            <div className={`flex justify-between items-center`}>
                <div className="flex items-center space-x-4">
                    {show_checkmark ? (
                        <Steps
                            className="h-[24px] w-[24px]"
                            variant="secondary"
                        >
                            <Media
                                size={0}
                                className="w-[17px] pt-[2px] pr-[1px]"
                                variant="tick"
                            />
                        </Steps>
                    ) : (
                        <Steps>{idx + 1 || 0}</Steps>
                    )}

                    <Typography size="md" allBold className="flex-1">
                        {name}
                    </Typography>
                </div>

                <div className="flex items-center">
                    {!show_stage_closed && (
                        <Typography
                            allBold={true}
                            size="sm"
                            className={`text-right flex items-center ${
                                idx === now_live_idx ? 'text-accent' : ''
                            }`}
                            variant={'custom'}
                        >
                            {right_value}
                        </Typography>
                    )}
                    {show_stage_closed && (
                        <div className="pl-3">
                            <Typography size="sm" allBold>
                                Closed
                            </Typography>
                        </div>
                    )}
                </div>
            </div>

            {(user ? active && !has_missed_event : active) && (
                <div className="border-l-2 border-border border-opacity-10 ml-3 mt-3 pl-[30px]">
                    <Component {...props} />
                </div>
            )}
        </div>
    );
};

export const ExpandableStages: React.FC<{
    options: StageOption[];
    current: number;
    event: ProjectEvent;
    has_reached_cutoff: boolean;
    is_crowdfunding_event_over: boolean;
    is_token_claim_event_over: boolean;
    hardcap: string;
    total_raised: string;
}> = ({
    options,
    current,
    event,
    has_reached_cutoff,
    is_crowdfunding_event_over,
    is_token_claim_event_over,
    hardcap,
    total_raised,
}) => {
    return (
        <div className="space-y-[10px]">
            {options.map((o, idx) => (
                <Stage
                    has_reached_cutoff={has_reached_cutoff}
                    key={o.name}
                    idx={idx}
                    current={current}
                    option={o}
                    total={options.length}
                    event={event}
                    options={options}
                    is_crowdfunding_event_over={is_crowdfunding_event_over}
                    is_token_claim_event_over={is_token_claim_event_over}
                    hardcap={hardcap}
                    total_raised={total_raised}
                />
            ))}
        </div>
    );
};
