import { ContentSectionType } from '@src/ts/constants';
import { IContentSection } from '@src/ts/interfaces';

import { FAQSection } from './FAQSection';
import { HeroSection } from './HeroSection';
import { LearningSection } from './LearningSection';
import { PartnerSection } from './PartnerSection';

export const ContentSection: React.FC<{
    className?: string;
    section: IContentSection;
}> = ({ section, className }) => {
    switch (section?.name) {
        case ContentSectionType.HeroSection:
            return <HeroSection section={section} className={className} />;
        case ContentSectionType.PartnerSection:
            return <PartnerSection section={section} className={className} />;
        case ContentSectionType.LearningSection:
            return <LearningSection section={section} className={className} />;
        case ContentSectionType.FAQ:
            return <FAQSection section={section} className={className} />;
        default:
            return (
                <div>
                    ContentSection {section?.name || 'UNDEFINED'} not yet
                    implemented!
                </div>
            );
    }
};
