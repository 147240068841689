import { gql } from '@apollo/client';

export const CONTENT_API = {
    RETRIEVE_COMPLETED_EVENTS: gql`
        query RetrieveCompletedEvents(
            $event_type: EventType!
            $amount: Int
            $offset: Int
        ) {
            retrieveCompletedEvents(
                event_type: $event_type
                amount: $amount
                offset: $offset
            ) {
                data {
                    name
                    network
                    ended_date
                    participants
                    total_raised
                    total_distributed
                    ath_roi
                    event_price
                    ath
                    fdv
                    ticker
                    hardcap
                    token_address
                    token_decimals
                    project_slug
                    project_logo
                    project_id
                }
                total_count
            }
        }
    `,

    RETRIEVE_CONTENT_SECTIONS: gql`
        query RetrieveContentSections(
            $hero_section_name: ContentSectionType!
            $parter_section_name: ContentSectionType!
            $learning_section_name: ContentSectionType!
            $faq_section_name: ContentSectionType!
        ) {
            retrieveHeroSection: retrieveContentSection(
                name: $hero_section_name
            ) {
                name
                images {
                    name
                    url
                    link
                }
                title
                data {
                    key
                    value
                }
            }
            retrievePartnerSection: retrieveContentSection(
                name: $parter_section_name
            ) {
                title
                name
                images {
                    name
                    url
                    link
                }
            }
            retrieveLearningSection: retrieveContentSection(
                name: $learning_section_name
            ) {
                title
                buttons {
                    text
                    url
                }

                name
                images {
                    name
                    url
                    link
                }
            }
            retrieveFAQSection: retrieveContentSection(
                name: $faq_section_name
            ) {
                name
                data {
                    key
                    value
                }
            }
        }
    `,
};
