import React from 'react';

import { StatisticType } from './constants';

const tuple = <T extends string[]>(...args: T) => args;

export type ExchangeTypes =
    | 'htx'
    | 'ascendex'
    | 'bitfinex'
    | 'dcx'
    | 'bybit'
    | 'gate'
    | 'kucoin'
    | 'pancakeswap'
    | 'uniswap'
    | 'bitget'
    | 'bitmart'
    | 'coinstore'
    | 'coinw'
    | 'lbank'
    | 'mexc'
    | 'poloniex'
    | 'bitpanda'
    | 'bingx'
    | 'coinex';

export type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

export type KeyEvent = React.KeyboardEvent<HTMLDivElement>;

export type NetworkType = 'ethereum' | 'bsc' | 'polygon';

export const staking_versions = tuple(
    'compound',
    'v1',
    'multi-asset',
    'legacy-compound',
    'liquidity',
    'legacy-liquidity',
);

export const legacy_staking_versions = tuple(
    'legacy-liquidity',
    'legacy-compound',
    'v1',
    'multi-asset',
);

export type SocialPlatforms =
    | 'facebook'
    | 'instagram'
    | 'cmc'
    | 'telegram'
    | 'twitter'
    | 'medium'
    | 'web'
    | 'mail'
    | 'youtube'
    | 'twitch'
    | 'github'
    | 'linkedin'
    | 'gitbook'
    | 'discord'
    | 'whitepaper';

export type StakingVersion = (typeof staking_versions)[number];

export type EventStatistic = {
    id: string;
    value: string;
};

export type EventStatistics = {
    [key in StatisticType]: number;
};
