import React from 'react';
import { BreakPoint, Card, Modal, Typography, useBreakpoint } from '@decub8/ui';

import { getExchangeData } from '@src/components/Layout/Header/hooks/utils';
import { CONTENT } from '@src/config';
import { BaseTokenSymbol } from '@src/contracts/index';
import { ProjectDetailsProps } from '@src/ts/props';

import { useProjectContext } from '../../context';

import { getOverviewItems } from './utils';

export const ProjectDetails: React.FC<ProjectDetailsProps> = ({
    token,
    event,
    description,
    explanation,
    links,
}) => {
    const items = getOverviewItems(token, event);
    const { event_page_images, accepted_exchanges } = CONTENT;
    const { roadmap, team, tokenomics, website } = links || {};

    const { show_buy_modal: show_modal, setShowBuyModal: setShowModal } =
        useProjectContext();

    const breakpoint = useBreakpoint();
    const is_mobile = breakpoint < BreakPoint.SM;

    const modal_width_class = `${
        accepted_exchanges?.length === 2 ? 'max-w-[295px]' : 'max-w-[404px]'
    }`;

    return (
        <div>
            {/* Project details section */}

            <div className="grid text-left grid-cols-2 md:grid-cols-3 gap-8 items-end justify-around">
                {items.map(({ value, label }, idx) => (
                    <div className="mb-4" key={`${label}-${value}-${idx}`}>
                        <Typography allBold size="2xl">
                            {value}
                        </Typography>

                        <Typography size="xs" variant="secondary">
                            {label}
                        </Typography>
                    </div>
                ))}
            </div>

            {/* Project explanation section */}

            <div className={`mt-20 space-y-5`}>
                <Typography allBold as="h2" size="3xl">
                    {description}
                </Typography>

                <Typography variant="secondary">{explanation}</Typography>
            </div>

            {/* Block images section */}

            {/* Block images section */}
            {event_page_images && (
                <div className={`space-y-4 mt-20`}>
                    <div className="flex space-x-4 w-full">
                        <div className="relative rounded-lg low-border flex-shrink-0 flex-grow max-w-[61.54%] max-h-[353px]">
                            <a
                                href={website?.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={event_page_images[0].image}
                                    alt={event_page_images[0].label}
                                    className="w-full h-full rounded-lg"
                                />
                                <Typography
                                    size={is_mobile ? 'md' : '2xl'}
                                    as={'h3'}
                                    className={`absolute  ${
                                        is_mobile
                                            ? 'top-2 left-2'
                                            : 'top-5 left-5'
                                    }`}
                                >
                                    Official website
                                </Typography>
                            </a>
                        </div>
                        <div className="relative rounded-lg low-border flex-shrink-0 flex-grow max-w-[35.90%] max-h-[353px]">
                            <a
                                href={roadmap?.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={event_page_images[1].image}
                                    alt={event_page_images[1].label}
                                    className="w-full h-full rounded-lg"
                                />
                                <Typography
                                    allBold
                                    as={'h3'}
                                    size={is_mobile ? 'md' : '2xl'}
                                    className={`absolute  ${
                                        is_mobile
                                            ? 'top-2 left-2'
                                            : 'top-5 left-5'
                                    }`}
                                >
                                    Roadmap
                                </Typography>
                            </a>
                        </div>
                    </div>
                    <div className="flex space-x-4 w-full">
                        <div className="relative rounded-lg low-border flex-shrink-0 flex-grow max-w-[35.90%] max-h-[353px]">
                            <a
                                href={team?.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={event_page_images[2].image}
                                    alt={event_page_images[2].label}
                                    className="w-full h-full rounded-lg"
                                />
                                <Typography
                                    allBold
                                    as={'h3'}
                                    size={is_mobile ? 'md' : '2xl'}
                                    className={`absolute  ${
                                        is_mobile
                                            ? 'top-2 left-2'
                                            : 'top-5 left-5'
                                    }`}
                                >
                                    Team
                                </Typography>
                            </a>
                        </div>
                        <div className="relative rounded-lg low-border flex-shrink-0 flex-grow max-w-[61.54%] max-h-[353px]">
                            <a
                                href={tokenomics?.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={event_page_images[3].image}
                                    alt={event_page_images[3].label}
                                    className="w-full h-full rounded-lg"
                                />
                                <Typography
                                    allBold
                                    as={'h3'}
                                    size={is_mobile ? 'md' : '2xl'}
                                    className={`absolute  ${
                                        is_mobile
                                            ? 'top-2 left-2'
                                            : 'top-5 left-5'
                                    }`}
                                >
                                    Tokenomics
                                </Typography>
                            </a>
                        </div>
                    </div>
                    <div className="flex space-x-4 w-full">
                        <div className="relative rounded-lg low-border flex-shrink-0 flex-grow max-w-[74.36%] max-h-[198px]">
                            <a
                                href={event_page_images[4].link}
                                target={'_blank'}
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={event_page_images[4].image}
                                    alt={event_page_images[4].label}
                                    className="w-full h-full rounded-lg"
                                />
                                <Typography
                                    allBold
                                    as={'h3'}
                                    className={`absolute  ${
                                        is_mobile
                                            ? 'top-2 left-2 w-[150px]'
                                            : 'top-5 left-5 w-[200px]'
                                    }`}
                                    size={is_mobile ? 'md' : '2xl'}
                                >
                                    How to participate in the event
                                </Typography>
                            </a>
                        </div>
                        <div
                            onClick={() =>
                                accepted_exchanges.length > 1
                                    ? setShowModal(true)
                                    : window.open(
                                          accepted_exchanges[0].link,
                                          '_blank',
                                      )
                            }
                            className="relative rounded-lg low-border cursor-pointer flex-shrink-0 flex-grow max-w-[23.08%] max-h-[198px]"
                        >
                            <img
                                src={event_page_images[5].image}
                                alt={event_page_images[5].label}
                                className="w-full h-full rounded-lg"
                            />
                            <Typography
                                allBold
                                as={'h3'}
                                size={is_mobile ? 'sm' : '2xl'}
                                className={`absolute ${
                                    is_mobile ? 'top-2 left-2' : 'top-5 left-5'
                                }`}
                            >
                                {`Buy ${BaseTokenSymbol}`}
                            </Typography>
                        </div>
                    </div>
                </div>
            )}

            <Modal
                show={show_modal}
                onClose={() => setShowModal(false)}
                className={modal_width_class}
            >
                <Typography
                    className={`${is_mobile ? 'mb-5' : 'mb-8'}`}
                    size="4xl"
                    allBold
                >
                    {`Buy ${BaseTokenSymbol}`}
                </Typography>
                <div
                    className={`grid grid-cols-3 mx-auto ${
                        is_mobile ? 'gap-3' : 'gap-5'
                    }`}
                >
                    {accepted_exchanges?.map(({ link, name }) => (
                        <a
                            key={`${link}-${name}`}
                            onClick={() => setShowModal(false)}
                            href={link}
                            target={'_blank'}
                            rel="noopener noreferrer"
                            className="mx-auto"
                        >
                            <Card
                                surface="two"
                                className={`hover:bg-surface-level-three ${
                                    is_mobile
                                        ? 'w-[90px] h-[90px] pb-2 pt-4'
                                        : 'w-[100px] h-[100px] py-4'
                                }`}
                            >
                                <img
                                    className={`mx-auto ${
                                        is_mobile ? 'h-8 w-8' : 'h-10 w-10'
                                    }`}
                                    src={getExchangeData(name).image}
                                />
                                <Typography
                                    size="sm"
                                    className={`text-center ${
                                        is_mobile ? 'mt-[8px]' : 'mt-[10px]'
                                    } `}
                                >
                                    {getExchangeData(name).title}
                                </Typography>
                            </Card>
                        </a>
                    ))}
                </div>
            </Modal>
        </div>
    );
};
